"use es6";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import H1 from "../components/Text/H1";
import H0 from "../components/Text/H0";
import P from "../components/Text/P";
import HR from "../components/Spacing/HR";
import H2 from "../components/Text/H2";
import Bold from "../components/Text/Bold";

const PoliciesPage = ({ dimensions = {} }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [document, setDocument] = useState(searchParams.get("document") | 0);

  return (
    <div
      style={{
        padding: "160px 30px",
        backgroundColor: "#ffffff",
      }}
    >
      <H0>📚 Policies</H0>
      <P style={{ fontSize: "14px", color: "#9a9a9a", margin: "25px" }}>
        Here's the tl;dr on what we're all about.
      </P>
      <br />
      <br />
      <HR />
      <br />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1.5", textAlign: "left" }}>
          <H2>Policies</H2>
          <br />
          <P
            style={{ color: document === 0 ? "#000000" : "#9a9a9a" }}
            onClick={() => {
              setDocument(0);
            }}
          >
            Privacy Policy
          </P>
          <HR />
          <P
            style={{ color: document === 1 ? "#000000" : "#9a9a9a" }}
            onClick={() => {
              setDocument(1);
            }}
          >
            Terms of Use
          </P>
        </div>
        <div style={{ flex: "1" }} />
        <div style={{ flex: "7", textAlign: "left" }}>
          {document === 0 ? (
            <div>
              <H0 style={{ fontSize: "36px" }}>🤫 Privacy Policy</H0>
              <br />
              <br />
              <P className="sub-text"> Last Updated: March 23, 2023</P>
              <P>
                This Privacy Policy describes how Centrally ("we," "us," or
                "our") collects, uses, and discloses your personal information
                when you use our community platform for institutions and
                enterprises, including our website, mobile applications, and any
                other features or content we offer (collectively, the
                "Services").
              </P>
              <P>
                By accessing or using the Services, you agree to the collection
                and use of your personal information in accordance with this
                Privacy Policy. If you do not agree with this Privacy Policy,
                you must not access or use the Services.
              </P>
              <Bold style={{ fontSize: "17px" }}>Information We Collect</Bold>
              <P>
                We collect information from you when you use the Services,
                including:
              </P>
              <P>
                a. Account Information: When you create an account ("Account"),
                we collect your name, email address, and any other information
                you choose to provide during the registration process.
              </P>
              <P>
                b. User Content: We collect any information you submit, post, or
                share when using the Services, such as messages, comments, and
                content you upload.
              </P>
              <P>
                c. Usage Information: We collect information about your
                interactions with the Services, such as the pages you visit, the
                links you click, and the features you use.
              </P>
              <P>
                d. Device and Technical Information: We collect information
                about the device you use to access the Services, such as your IP
                address, browser type, operating system, and device identifiers.
              </P>
              <P>
                e. Cookies and Similar Technologies: We use cookies and similar
                technologies to collect information about your use of the
                Services, your preferences, and your interactions with our
                content and advertisements. You can manage your cookie
                preferences through your browser settings.
              </P>

              <Bold style={{ fontSize: "17px" }}>
                How We Use Your Information
              </Bold>

              <P>We use the information we collect to:</P>
              <P>a. Provide, maintain, and improve the Services;</P>
              <P>
                b. Communicate with you, including sending you notifications,
                updates, and marketing materials;
              </P>
              <P>
                c. Analyze and understand how users interact with the Services
                to optimize and develop new features;
              </P>
              <P>
                d. Personalize your experience, such as by providing tailored
                content and recommendations;
              </P>
              <P>
                e. Protect the security and integrity of the Services, and
                investigate and prevent unauthorized access, misuse, or fraud;
              </P>
              <P>
                f. Comply with legal obligations, resolve disputes, and enforce
                our agreements, including our Terms of Service.
              </P>

              <Bold style={{ fontSize: "17px" }}>
                Information Sharing and Disclosure
              </Bold>
              <P>
                We may share your information with third parties under the
                following circumstances:
              </P>
              <P>
                a. Service Providers: We may share your information with
                third-party service providers who perform services on our
                behalf, such as hosting, analytics, and customer support.
              </P>
              <P>
                b. Affiliates and Partners: We may share your information with
                our affiliates and partners for the purpose of providing,
                maintaining, and improving the Services.
              </P>
              <P>
                c. Legal Requirements: We may disclose your information if
                required to do so by law or in response to a legal request, such
                as a subpoena, court order, or government demand.
              </P>
              <P>
                d. Business Transfers: In the event of a merger, acquisition,
                reorganization, or sale of assets, we may transfer your
                information to the acquiring entity.
              </P>
              <P>
                e. Consent: We may share your information with your consent or
                at your direction, such as when you choose to share information
                with other users or third-party services.
              </P>
              <Bold style={{ fontSize: "17px" }}>Data Retention</Bold>
              <P>
                We retain your personal information for as long as necessary to
                provide the Services and fulfill the purposes outlined in this
                Privacy Policy, unless a longer retention period is required or
                permitted by law.
              </P>
              <Bold style={{ fontSize: "17px" }}>Security</Bold>
              <P>
                We take appropriate measures to protect your personal
                information from unauthorized access, use, or disclosure.
                However, no method of transmission or storage is completely
                secure, and we cannot guarantee the absolute security of your
                information.
              </P>
              <Bold style={{ fontSize: "17px" }}>
                International Data Transfers
              </Bold>

              <P>
                By using the Services, you understand that your information may
                be transferred to, stored, and processed in countries other than
                your country of residence, including the United States. These
                countries may have different data protection laws and
                regulations than your country of residence. By using the
                Services, you consent to the transfer of your information to
                these countries.
              </P>
              <Bold style={{ fontSize: "17px" }}>Your Rights and Choices</Bold>
              <P>
                Depending on your jurisdiction, you may have certain rights with
                respect to your personal information, including the right to
                access, correct, delete, or restrict the processing of your
                information. To exercise these rights, please contact us at
                [email protected] We will respond to your request in accordance
                with applicable law.
              </P>
              <P>
                Additionally, you may unsubscribe from marketing communications
                by following the "Unsubscribe" link in any email we send or by
                contacting us at the email address provided above.
              </P>

              <Bold style={{ fontSize: "17px" }}>Children's Privacy</Bold>
              <P>
                The Services are not intended for use by children under the age
                of 13. We do not knowingly collect personal information from
                children under the age of 13. If we become aware that we have
                collected personal information from a child under the age of 13,
                we will take steps to delete such information from our records.
              </P>

              <Bold style={{ fontSize: "17px" }}>
                Changes to This Privacy Policy
              </Bold>
              <P>
                We may update this Privacy Policy from time to time. If we make
                material changes, we will notify you through the Services or by
                other means, such as email. By continuing to access or use the
                Services after those changes become effective, you agree to be
                bound by the revised Privacy Policy.
              </P>
              <Bold style={{ fontSize: "17px" }}>Contact Information</Bold>
              <P>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us at [email protected]
              </P>
              <P>Thank you for using Centrally!</P>
            </div>
          ) : (
            <div>
              <H0 style={{ fontSize: "36px" }}>📑 Terms of Use</H0>
              <br />
              <br />
              <P className="sub-text">Last Updated: March 23, 2023</P>
              <P>
                Welcome to Centrally, a community platform for institutions and
                enterprises ("Platform"). These Terms of Service ("Terms")
                govern your access to and use of the services provided by
                Centrally, including our website, mobile applications, and any
                other features or content we offer (collectively, the
                "Services").
              </P>
              <P>
                Please read these Terms carefully before using the Services. By
                accessing or using the Services, you agree to be bound by these
                Terms. If you do not agree with these Terms, you must not access
                or use the Services.
              </P>
              <Bold style={{ fontSize: "17px" }}>Eligibility</Bold>
              <P>
                You must be at least 13 years of age to use the Services. By
                accessing or using the Services, you represent and warrant that
                you are at least 13 years old and have the legal capacity to
                agree to these Terms.
              </P>
              <Bold style={{ fontSize: "17px" }}>Account Registration</Bold>
              <P>
                To access certain features of the Services, you must create an
                account ("Account"). You agree to provide accurate, current, and
                complete information during the registration process and keep
                your Account information up-to-date. You are responsible for
                safeguarding your Account information and for any activities or
                actions taken using your Account. Centrally is not liable for
                any loss or damage resulting from your failure to safeguard your
                Account information.
              </P>

              <Bold style={{ fontSize: "17px" }}>Acceptable Use</Bold>
              <P>
                You agree not to use the Services for any unlawful purposes or
                in a way that violates these Terms. Prohibited activities
                include, but are not limited to:
              </P>
              <P>a. Harassing, bullying, or threatening other users;</P>
              <P>
                b. Posting or sharing content that is offensive, obscene, or
                otherwise objectionable;
              </P>
              <P>c. Spamming or sending unsolicited messages;</P>
              <P>
                d. Impersonating another person or entity, or falsely
                representing your affiliation with an institution or enterprise;
              </P>
              <P>e. Violating the intellectual property rights of others;</P>
              <P>
                f. Using the Services for any commercial purposes, unless
                expressly permitted by Centrally;
              </P>
              <P>
                g. Attempting to gain unauthorized access to the Services or the
                accounts of other users;
              </P>
              <P>
                h. Engaging in any activity that interferes with the proper
                functioning of the Services.
              </P>
              <Bold style={{ fontSize: "17px" }}>Intellectual Property</Bold>
              <P>
                All content and materials available on the Services, including
                but not limited to text, graphics, logos, and software, are the
                property of Centrally or its licensors and are protected by
                copyright, trademark, and other intellectual property laws. You
                are granted a limited, non-exclusive, non-transferable,
                revocable license to access and use the Services for personal,
                non-commercial purposes, subject to these Terms.
              </P>
              <Bold style={{ fontSize: "17px" }}>
                Freemium and Subscription Services
              </Bold>
              <P>
                Centrally offers both free ("Freemium") and subscription-based
                ("Premium") Services. By subscribing to our Premium Services,
                you agree to pay the fees indicated at the time of purchase.
                Fees may be subject to change. Subscription payments are
                non-refundable, except as required by applicable law or as
                stated in our refund policy.
              </P>
              <Bold style={{ fontSize: "17px" }}>Termination</Bold>
              <P>
                Centrally reserves the right to suspend or terminate your access
                to the Services, with or without notice, if we believe you have
                violated these Terms or if your Account remains inactive for an
                extended period of time. You may terminate your Account at any
                time by following the instructions provided within the Services.
              </P>
              <Bold style={{ fontSize: "17px" }}>Disclaimers</Bold>
              <P>
                The Services are provided on an "as is" and "as available"
                basis, without warranties of any kind, either express or
                implied. Centrally does not warrant that the Services will be
                uninterrupted, error-free, or completely secure.
              </P>
              <Bold style={{ fontSize: "17px" }}>Limitation of Liability</Bold>
              <P>
                To the maximum extent permitted by law, Centrally shall not be
                liable for any indirect, incidental, special, consequential, or
                punitive damages, or any loss of profits or revenues, whether
                incurred directly or indirectly, or any loss of data, use,
                goodwill, or other intangible losses, resulting from (a) your
                access to or use of, or inability to access or use, the
                Services; (b) any conduct or content of any third party on the
                Services; or (c) unauthorized access, use, or alteration of your
                transmissions or content.
              </P>
              <P>
                In no event shall Centrally's aggregate liability for all claims
                relating to the Services exceed the greater of one hundred U.S.
                dollars (USD 100) or the amount you have paid to Centrally for
                the use of the Services in the past twelve months.
              </P>
              <Bold style={{ fontSize: "17px" }}>Indemnification</Bold>
              <P>
                You agree to indemnify, defend, and hold harmless Centrally, its
                affiliates, and their respective officers, directors, employees,
                and agents from and against any and all claims, liabilities,
                damages, losses, or expenses, including reasonable attorneys'
                fees and costs, arising out of or in any way connected with your
                access to or use of the Services or your violation of these
                Terms.
              </P>
              <Bold style={{ fontSize: "17px" }}>
                Governing Law and Jurisdiction
              </Bold>
              <P>
                These Terms and any dispute or claim arising out of or in
                connection with them shall be governed by and construed in
                accordance with the laws of the jurisdiction in which Centrally
                is based, without regard to its conflict of law provisions. You
                agree to submit to the exclusive jurisdiction of the courts
                located in that jurisdiction to resolve any legal matter arising
                from these Terms or your use of the Services.
              </P>
              <Bold style={{ fontSize: "17px" }}>Changes to These Terms</Bold>
              <P>
                Centrally reserves the right to modify these Terms at any time.
                If we make material changes to these Terms, we will notify you
                through the Services or by other means, such as email. By
                continuing to access or use the Services after those changes
                become effective, you agree to be bound by the revised Terms.
              </P>
              <Bold style={{ fontSize: "17px" }}>Miscellaneous</Bold>
              <P>
                These Terms, together with our Privacy Policy and any other
                agreements expressly incorporated by reference, constitute the
                entire agreement between you and Centrally with respect to the
                Services and supersede all prior or contemporaneous
                understandings, whether written or oral. If any provision of
                these Terms is found to be invalid or unenforceable, that
                provision shall be limited or eliminated to the minimum extent
                necessary, and the remaining provisions of these Terms shall
                remain in full force and effect.
              </P>
              <Bold style={{ fontSize: "17px" }}>Contact Information</Bold>
              <P>
                If you have any questions about these Terms, please contact us
                at [email protected]
              </P>
              <P>Thank you for using Centrally!</P>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PoliciesPage;
