"use es6";

import React, { useState } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import Bold from "../Text/Bold";
import H1 from "../Text/H1";

import exit from "../../graphics/icons/exit.svg";
import school from "../../graphics/icons/school.svg";
import checked from "../../graphics/icons/filter/checked.svg";
import unchecked from "../../graphics/icons/filter/unchecked.svg";
import envelope from "../../graphics/icons/envelope.svg";
import padlock from "../../graphics/icons/padlock.svg";
import person from "../../graphics/icons/person.svg";
import eye from "../../graphics/icons/eye.svg";
import P from "../Text/P";
import HR from "../Spacing/HR";
import Card from "../Cards/Card";
import Button from "../Buttons/Button";
import H2 from "../Text/H2";
import Label from "../Text/Label";
import Loading from "../Loading/Loading";
import UploadImageViewport from "./UploadImageViewport";

const S3_BUCKET = "centrally-organizations";
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const EditAccountViewport = ({
  credentials = {},
  updateCredentials = {},
  member = {},
  setMembers = {},
  getCredentials = {},
  setEditAccountViewportVisible = {},
}) => {
  const [uploadImageViewportVisible, setUploadImageViewportVisible] =
    useState(false);

  const [image, setImage] = useState(credentials.image || "");
  const [name, setName] = useState(credentials.name);
  const [email, setEmail] = useState(credentials.email);
  const [password, setPassword] = useState();
  const [confirmation, setConfirmation] = useState();
  const [description, setDescription] = useState(credentials.description);
  const [subscription, setSubscription] = useState(credentials.subscription);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [editingAccount, setEditingAccount] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const uploadFile = (file, id) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${id}.jpeg`,
      ContentEncoding: "base64",
    };
    // ${file.name.split(".").pop()
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        // if (err) console.log(err);
      });
  };

  const deleteFile = async () => {
    myBucket
      .deleteObject({
        Bucket: S3_BUCKET,
        Key: `${credentials.image.split("/")[3]}`,
      })
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        // if (err) console.log(err);
      });
  };

  const handleEditAccount = () => {
    const id = Date.now();
    deleteFile();
    uploadFile(selectedFile, id);
    const requestBody = {
      name: name,
      email: email.toLowerCase(),
      password: password,
      description: description,
      subscription: subscription,
      image: !!selectedFile
        ? `https://centrally-organizations.s3.amazonaws.com/${id}.jpeg`
        : null,
    };
    setEditingAccount(true);
    axios
      .post(
        `${process.env.REACT_APP_ORGANIZATIONS_API_URL}/organizations/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then(async (response) => {
        setEditAccountViewportVisible(false);
        updateCredentials(response.data.organization);
        window.location.reload();
      });
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        zIndex: "20",
      }}
    >
      <Card
        type="white"
        style={{
          width: "500px",
          height: "fit-content",
          margin: "auto",
          padding: "20px 0px 0px 0px",
        }}
      >
        <div>
          <img
            style={{
              width: "24px",
              height: "24px",
              margin: "0px 500px 0px 0px",
            }}
            src={exit}
            alt="An x-mark icon."
            onClick={() => {
              setEditAccountViewportVisible(false);
            }}
          />
          <H2>Edit Account</H2>
          <div>
            <img
              src={image || credentials.image}
              alt={`${credentials.name}'s organization image.`}
              style={{
                margin: "20px 0px 0px 0px",
                borderRadius: "100%",
                overflow: "wrap",
                overflowWrap: "normal",
                width: "128px",
                height: "128px",
              }}
            />
            <br />
            <br />
            <Bold
              style={{ color: "#11a5e8" }}
              onClick={() => {
                setUploadImageViewportVisible(true);
              }}
            >
              Change Image
            </Bold>
          </div>
          <Label>Edit Organization Name</Label>
          <Card type={!!name ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                maxHeight: "fit-content",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    padding: "12px 7.5px 10px 0px",
                    filter: !!name ? "brightness(0%)" : "",
                  }}
                  src={school}
                  alt={`An icon of a school building.`}
                  width="24"
                  height="24"
                />
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!name ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={name}
                  placeholder={credentials.name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
            </div>
          </Card>
          <Label>Edit Member Email</Label>
          <Card type={!!email ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                maxHeight: "fit-content",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    padding: "12px 7.5px 10px 0px",
                    filter: !!email ? "brightness(0%)" : "",
                  }}
                  src={envelope}
                  alt={`An icon of an envelope.`}
                  width="24"
                  height="24"
                />
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!email ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={email}
                  placeholder={credentials.email}
                  onChange={(event) => {
                    setEmail(event.target.value.toLowerCase());
                  }}
                />
              </div>
            </div>
          </Card>
          <Button
            type="light"
            onClick={() => {
              setChangingPassword(!changingPassword);
            }}
          >
            {changingPassword ? "Cancel" : "Change Password"}
          </Button>
          {changingPassword && (
            <div>
              <Label>Password</Label>
              <Card type={!!password ? "active" : "inactive"}>
                <div
                  style={{
                    display: "flex",
                    maxHeight: "fit-content",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <img
                      style={{
                        padding: "12px 7.5px 10px 0px",
                        filter: !!password ? "brightness(0%)" : "",
                      }}
                      src={padlock}
                      alt={`An icon of an envelope.`}
                      width="24"
                      height="24"
                    />
                    <input
                      type={!!passwordVisible ? "text" : "password"}
                      style={{
                        alignItems: "center",
                        fontSize: "15px",
                        width: "100%",
                        border: "none",
                        color: !!password ? "#000000" : "#9a9a9a",
                        backgroundColor: "rgba(0,0,0,0)",
                      }}
                      placeholder="Enter your password."
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                    />
                    <img
                      style={{
                        padding: "12px 0px 10px 0px",
                        filter: !!passwordVisible ? "brightness(0%)" : "",
                      }}
                      src={eye}
                      alt={`An icon of an eye.`}
                      width="28"
                      height="28"
                      onClick={() => {
                        setPasswordVisible(!passwordVisible);
                      }}
                    />
                  </div>
                </div>
              </Card>
              <Label>Confirm Password</Label>
              <Card type={!!confirmation ? "active" : "inactive"}>
                <div
                  style={{
                    display: "flex",
                    maxHeight: "fit-content",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <img
                      style={{
                        padding: "12px 7.5px 10px 0px",
                        filter: !!confirmation ? "brightness(0%)" : "",
                      }}
                      src={padlock}
                      alt={`An icon of an envelope.`}
                      width="24"
                      height="24"
                    />
                    <input
                      type={!!passwordVisible ? "text" : "password"}
                      style={{
                        alignItems: "center",
                        fontSize: "15px",
                        width: "100%",
                        border: "none",
                        color: !!confirmation ? "#000000" : "#9a9a9a",
                        backgroundColor: "rgba(0,0,0,0)",
                      }}
                      placeholder="Confirm your password."
                      onChange={(event) => {
                        setConfirmation(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </Card>
            </div>
          )}
          <Label>Edit Organization Description</Label>
          <Card type={!!description ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                maxHeight: "fit-content",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100px",
                }}
              >
                <textarea
                  className="normal"
                  style={{
                    fontSize: "15px",
                    padding: "15px 5px",
                    width: "100%",
                    border: "none",
                    color: !!description ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                    overflowWrap: "anywhere",
                  }}
                  value={description}
                  placeholder="Enter your organization's description."
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                />
              </div>
            </div>
          </Card>
          <div style={{ display: "flex" }}>
            {["free", "standard", "deluxe"].map((tempSubscription, index) => (
              <div
                key={index}
                onClick={() => {
                  setSubscription(tempSubscription);
                }}
                style={{ padding: "0px 20px 0px 0px" }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Bold>{tempSubscription.toUpperCase()}</Bold>
                    <div>
                      <input
                        style={{ height: "20px", width: "20px" }}
                        type="radio"
                        value={tempSubscription}
                        checked={
                          !!subscription && subscription === tempSubscription
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {editingAccount && <Loading width="64px" />}
          <Button
            type="dark"
            style={{ margin: "10px 0px", width: "100%" }}
            onClick={() => {
              handleEditAccount();
            }}
          >
            SUBMIT
          </Button>
        </div>
      </Card>
      {!!uploadImageViewportVisible && (
        <UploadImageViewport
          image={image}
          setImage={setImage}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          uploadImageViewportVisible={uploadImageViewportVisible}
          setUploadImageViewportVisible={setUploadImageViewportVisible}
        />
      )}
    </div>
  );
};

export default EditAccountViewport;
