"use es6";

import React, { useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import cryptoRandomString from "crypto-random-string";
import Bold from "../Text/Bold";
import H1 from "../Text/H1";

import exit from "../../graphics/icons/exit.svg";
import checked from "../../graphics/icons/filter/checked.svg";
import unchecked from "../../graphics/icons/filter/unchecked.svg";
import envelope from "../../graphics/icons/envelope.svg";
import padlock from "../../graphics/icons/padlock.svg";
import person from "../../graphics/icons/person.svg";
import eye from "../../graphics/icons/eye.svg";
import P from "../Text/P";
import HR from "../Spacing/HR";
import Card from "../Cards/Card";
import Button from "../Buttons/Button";
import H2 from "../Text/H2";
import Label from "../Text/Label";
import Loading from "../Loading/Loading";

const CsvAddViewport = ({
  credentials = {},
  setMembers = {},
  getCredentials = {},
  csvAddViewportVisible = {},
  setCsvAddViewportVisible = {},
}) => {
  const [message, setMessage] = useState("");
  const [newMembers, setNewMembers] = useState([]);
  const [addingMembers, setAddingMembers] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const activeViewport = csvAddViewportVisible === true;

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const handleCsvAdd = async () => {
    setMessage(null);
    setAddingMembers(true);
    let count = newMembers.length - 1;
    for (let i = 0; i < newMembers.length - 1; i++) {
      if (activeViewport) {
        const id = Date.now();

        setMessage(`Adding ${newMembers[i][1].toLowerCase()}...`);

        const newPassword = cryptoRandomString({
          length: 6,
          type: "numeric",
        });

        axios.post(
          `${process.env.REACT_APP_EMAIL_API_URL}/authenticate`,
          {
            message: "create",
            to: newMembers[i][1].toLowerCase(),
            organization: credentials.name,
            otp: newPassword,
            name: newMembers[i][0],
          },
          requestConfig
        );

        const requestBody = {
          id: id,
          name: newMembers[i][0],
          email: newMembers[i][1].toLowerCase(),
          password: newPassword,
          organization: credentials.id,
          role: newMembers[i][2],
          verified: newMembers[i][3].toLowerCase() === "true",
          image:
            "https://centrally-defaults.s3.amazonaws.com/defaultprofileimage.png",
        };
        // await new Promise((resolve) => setTimeout(resolve, 500));
        activeViewport &&
          (await axios
            .post(
              `${process.env.REACT_APP_USERS_API_URL}/signup`,
              requestBody,
              requestConfig
            )
            .then(async (response) => {
              const requestBody2 = {
                members: {
                  [`${id}`]: {
                    id: id,
                    name: newMembers[i][0],
                    email: newMembers[i][1].toString().toLowerCase(),
                    role: newMembers[i][2],
                    verified: newMembers[i][3].toLowerCase() === "true",
                    image:
                      "https://centrally-defaults.s3.amazonaws.com/defaultprofileimage.png",
                  },
                },
              };
              // await new Promise((resolve) => setTimeout(resolve, 500));
              await axios
                .post(
                  `${process.env.REACT_APP_ORGANIZATIONS_API_URL}/organizations/${credentials.id}`,
                  requestBody2,
                  requestConfig
                )
                .then((response) => {
                  count -= 1;
                  getCredentials();
                  setMembers((prevMembers) => ({
                    ...prevMembers,
                    [`${id}`]: {
                      id: id,
                      name: newMembers[i][0],
                      email: newMembers[i][1].toString().toLowerCase(),
                      role: newMembers[i][2],
                      verified: newMembers[i][3].toLowerCase() === "true",
                      image:
                        "https://centrally-defaults.s3.amazonaws.com/defaultprofileimage.png",
                    },
                  }));
                });
            })
            .catch((error) => {
              setMessage(error.response.data.message);
              setAddingMembers(false);
            }));
      }
    }
    if (count === 0) {
      setCsvAddViewportVisible(false);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        zIndex: "20",
      }}
    >
      <Card type="white" style={{ height: "fit-content", margin: "auto 20px" }}>
        <label
          id="browse-files-label"
          for="browse-files"
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#27c888",
          }}
        >
          <div
            style={{
              position: "relative",
              margin: "auto",
              fontSize: "14px",
              height: "fit-content",
            }}
          >
            Upload .csv File
          </div>
        </label>
        <input
          id="browse-files"
          type="file"
          accept=".csv,.xlsx,.xls"
          onChange={(e) => {
            setSelectedFile(e.target.files[0]);
            const files = e.target.files;
            if (files) {
              Papa.parse(files[0], {
                complete: function (results) {
                  setNewMembers(results.data);
                  setMessage(null);
                },
              });
            }
          }}
        />

        {!!selectedFile && newMembers.length > 0 ? (
          <div>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflow: "scroll",
              }}
            >
              <div
                style={{
                  margin: "auto",
                  width: "fit-content",
                  textAlign: "left",
                }}
              >
                <table style={{ margin: "0px 0px 0px 10px" }}>
                  <thead>
                    <tr>
                      <th style={{ padding: "10px" }}>Full Name</th>
                      <th style={{ padding: "10px" }}>Email</th>
                      <th style={{ padding: "10px" }}>Role</th>
                      <th style={{ padding: "10px" }}>Verification</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newMembers.map((item, index) => {
                      return (
                        <tr>
                          <td style={{ padding: "7.5px 15px" }}>{item[0]}</td>
                          <td style={{ padding: "7.5px 15px" }}>{item[1]}</td>
                          <td style={{ padding: "7.5px 15px" }}>{item[2]}</td>
                          <td style={{ padding: "7.5px 15px" }}>{item[3]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {addingMembers && <Loading width="64px" />}
            <P
              style={{
                fontSize: "11px",
                color: "#888888",
              }}
            >
              {message}
            </P>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Button
                type="dark"
                style={{ margin: "10px 0px", width: "300px" }}
                onClick={() => {
                  setMessage(null);
                  handleCsvAdd();
                }}
              >
                Confirm Add New Members
              </Button>
            </div>
          </div>
        ) : (
          <p>Your .csv file preview will appear here.</p>
        )}
        <Button
          style={{ color: "#d2566b", margin: "10px 0px", width: "300px" }}
          onClick={() => {
            setCsvAddViewportVisible(false);
          }}
        >
          Cancel
        </Button>
      </Card>
    </div>
  );
};

export default CsvAddViewport;
