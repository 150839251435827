"use es6";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import H1 from "../components/Text/H1";
import AuthenticationViewport from "../components/Viewports/AuthenticationViewport";
import Button from "../components/Buttons/Button";
import AddMemberViewport from "../components/Viewports/AddMemberViewport";
import Bold from "../components/Text/Bold";
import HR from "../components/Spacing/HR";
import H2 from "../components/Text/H2";
import Card from "../components/Cards/Card";
import EditMemberViewport from "../components/Viewports/EditMemberViewport";
import ProfileButton from "../components/Buttons/ProfileButton";
import P from "../components/Text/P";
import EditAccountViewport from "../components/Viewports/EditAccountViewport";
import CsvAddViewport from "../components/Viewports/CsvAddViewport";

const AccountPage = ({
  isDesktop = {},
  credentials = {},
  dimensions = {},
  updateCredentials = {},
}) => {
  const [addMemberViewportVisible, setAddMemberViewportVisible] =
    useState(false);
  const [csvAddViewportVisible, setCsvAddViewportVisible] = useState(false);
  const [editMemberViewportVisible, setEditMemberViewportVisible] =
    useState(false);
  const [editAccountViewportVisible, setEditAccountViewportVisible] =
    useState(false);
  const [members, setMembers] = useState({});
  const [member, setMember] = useState(null);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getCredentials = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_ORGANIZATIONS_API_URL}/organizations/${credentials.id}`,
        requestConfig
      )
      .then((response) => {
        updateCredentials(response.data);
        setMembers(response.data.members);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getCredentials();
  }, []);

  const removeMember = (memberid) => {
    axios
      .post(
        `${process.env.REACT_APP_ORGANIZATIONS_API_URL}/organizations/${credentials.id}`,
        { deletingMember: memberid },
        requestConfig
      )
      .then(() => {
        axios.post(
          `${process.env.REACT_APP_USERS_API_URL}/users/${memberid}`,
          { deleting: true },
          requestConfig
        );
        setMembers((prevMembers) =>
          Object.keys(prevMembers)
            .filter((key) => !key.includes(memberid))
            .reduce((obj, key) => {
              return Object.assign(obj, {
                [key]: prevMembers[key],
              });
            }, {})
        );
        getCredentials();
      });
  };

  return (
    <div style={{ margin: "120px 30px" }}>
      {!!credentials && !!credentials.members && (
        <div>
          <div style={{ textAlign: "left", padding: "30px 0px" }}>
            <img
              src={credentials.image}
              alt={`${credentials.name}'s organization image.`}
              style={{
                borderRadius: "100%",
                overflow: "wrap",
                overflowWrap: "normal",
                width: "128px",
                height: "128px",
              }}
            />
            <div
              style={{
                alignContent: "center",
                padding: "10px 0px 0px 0px",
              }}
            >
              <H1>{credentials.name}</H1>
              {!!credentials.description ? (
                <P>{credentials.description}</P>
              ) : (
                <P style={{ color: "#9a9a9a" }}>
                  Hit "Edit Account Info" to add a description!
                </P>
              )}
              <div style={{ padding: "2.5px 0px 0px 0px" }}>
                <Button
                  type="dark"
                  style={{ width: "200px" }}
                  onClick={() => {
                    setEditAccountViewportVisible(true);
                  }}
                >
                  Edit Account Info
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "sticky",
              display: isDesktop && "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{ flex: "9", minWidth: "200px", margin: "10px 0px" }}
              type="dark"
              onClick={() => {
                setAddMemberViewportVisible(true);
              }}
            >
              Add Member
            </Button>{" "}
            <Button
              style={{
                flex: "1",
                minWidth: "150px",
                margin: "10px 0px",
                backgroundColor: "#27c888",
              }}
              type="dark"
              onClick={() => {
                setCsvAddViewportVisible(true);
              }}
            >
              .CSV Add
            </Button>
          </div>
          <div
            style={{
              margin: "25px",
              width: "inherit",
              height: "500px",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#ffffff",
                padding: "5px",
                position: "sticky",
                top: "0px",
                margin: "0px 0px 0px -5px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "150px",
                    fontSize: "16px",
                    overflowWrap: "break-word",
                    padding: "5px",
                  }}
                >
                  Member ID:
                </div>
                <div
                  style={{
                    textAlign: "left",
                    width: "150px",
                    fontSize: "16px",
                    overflowWrap: "break-word",
                    padding: "5px",
                  }}
                >
                  Member Name:
                </div>
                <div
                  style={{
                    textAlign: "left",
                    width: "150px",
                    fontSize: "16px",
                    overflowWrap: "break-word",
                    padding: "5px",
                  }}
                >
                  Member Email:
                </div>
                <div
                  style={{
                    textAlign: "left",
                    width: "150px",
                    fontSize: "16px",
                    overflowWrap: "break-word",
                    padding: "5px",
                  }}
                >
                  Member Verification:
                </div>
                <div
                  style={{
                    textAlign: "left",
                    width: "150px",
                    fontSize: "16px",
                    overflowWrap: "break-word",
                    padding: "5px",
                  }}
                >
                  Member Role:
                </div>
              </div>
            </div>
            <HR />
            {!!credentials && (
              <div>
                {Object.keys(members)
                  .sort((a, b) => b - a)
                  .map((member, index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                              width: "150px",
                              fontSize: "16px",
                              overflowWrap: "break-word",
                              padding: "5px",
                            }}
                          >
                            {member}
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              width: "150px",
                              fontSize: "16px",
                              overflowWrap: "break-word",
                              padding: "5px",
                            }}
                          >
                            {members[member].name}
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              width: "150px",
                              fontSize: "16px",
                              overflowWrap: "break-word",
                              padding: "5px",
                            }}
                          >
                            {members[member].email}
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              width: "150px",
                              fontSize: "16px",
                              overflowWrap: "break-word",
                              padding: "5px",
                            }}
                          >
                            {JSON.stringify(members[member].verified)}
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              width: "150px",
                              fontSize: "16px",
                              overflowWrap: "break-word",
                              padding: "5px",
                            }}
                          >
                            {members[member].role}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            margin: "0px 10px 0px -10px",
                          }}
                        >
                          <Button
                            style={{
                              padding: "0px 20px",
                            }}
                            onClick={() => {
                              setMember(members[member]);
                              setEditMemberViewportVisible(true);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            style={{
                              color: "#d2566b",
                              padding: "0px 5px",
                              margin: "0px 10px",
                            }}
                            onClick={() => {
                              console.log(member);
                              removeMember(member);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                      <HR style={{ filter: "brightness(0)" }} />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
      {!!addMemberViewportVisible && (
        <AddMemberViewport
          credentials={credentials}
          members={members}
          setMembers={setMembers}
          getCredentials={getCredentials}
          addMemberViewportVisible={addMemberViewportVisible}
          setAddMemberViewportVisible={setAddMemberViewportVisible}
        />
      )}
      {!!csvAddViewportVisible && (
        <CsvAddViewport
          credentials={credentials}
          members={members}
          setMembers={setMembers}
          getCredentials={getCredentials}
          csvAddViewportVisible={csvAddViewportVisible}
          setCsvAddViewportVisible={setCsvAddViewportVisible}
        />
      )}
      {!!editMemberViewportVisible && (
        <EditMemberViewport
          credentials={credentials}
          member={member}
          setMembers={setMembers}
          getCredentials={getCredentials}
          setEditMemberViewportVisible={setEditMemberViewportVisible}
        />
      )}
      {!!editAccountViewportVisible && (
        <EditAccountViewport
          credentials={credentials}
          updateCredentials={updateCredentials}
          getCredentials={getCredentials}
          setEditAccountViewportVisible={setEditAccountViewportVisible}
        />
      )}
    </div>
  );
};

export default AccountPage;
