"use es6";

import React from "react";
import H1 from "../components/Text/H1";
import P from "../components/Text/P";

const UnknownPage = ({ dimensions = {} }) => {
  return (
    <div
      style={{
        width: "80%",
        marginTop: "120px",
      }}
    >
      <H1 className="label" style={{ fontSize: "48px" }}>
        404: Page Not Found.
      </H1>
      <H1>This page may not currently exist today, but it could some day!</H1>
      <P>
        <i>
          "If something's bound to happen, it wil happen... Right time, right
          person, and for the best reason."
        </i>
        - Aristotle
      </P>
      <br />
      <br />
      <br />
    </div>
  );
};

export default UnknownPage;
