"use es6";

import React from "react";

const H1 = ({ children = "[insert text]", ...buttonProps }) => {
  return (
    <p className="heading-1" {...buttonProps}>
      {children}
    </p>
  );
};

export default H1;
