"use es6";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { getUser } from "../../services/AuthService";

import banner from "../../graphics/logos/banner.svg";
import linkedin from "../../graphics/logos/linkedin_black.svg";
import facebook from "../../graphics/logos/facebook_black.svg";
import twitter from "../../graphics/logos/twitter_black.svg";
import groups from "../../graphics/icons/groups.svg";
import cancel from "../../graphics/icons/cancel.svg";
import bell from "../../graphics/icons/bell.svg";
import bubbles from "../../graphics/icons/bubbles.svg";
import person from "../../graphics/icons/person.svg";
import P from "../Text/P";
import ProfileButton from "../Buttons/ProfileButton";
import Button from "../Buttons/Button";
import Bold from "../Text/Bold";

const Footer = ({
  isDesktop = {},
  credentials = {},
  setCredentials = {},
  notificationsViewportVisible = {},
  setNotificationsViewportVisible = {},
  createPostViewportVisible = {},
  setCreatePostViewportVisible = {},
  shareDropdownVisible = {},
  setShareDropdownVisible = {},
  headerMode = {},
  setHeaderMode = {},
  activePage = {},
  setActivePage = {},
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [pageLoaderVisible, setPageLoaderVisible] = useState(false);

  const loadPage = async () => {
    setPageLoaderVisible(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    setPageLoaderVisible(false);
  };

  return (
    <div
      className="footer"
      style={{
        padding: "30px 30px 20px 30px",
      }}
    >
      <br />
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ textAlign: "left", width: "200px" }}>
          <img
            src={banner}
            style={{
              height: "25px",
            }}
            onClick={() => {
              navigate("/");
              setActivePage("home");
              window.scrollTo(0, 0);
              loadPage();
            }}
          />
          <P
            style={{
              margin: "5px 0px 0px 0px",
              color: "#555",
              fontSize: "15px",
            }}
          >
            Your Organized Community.
          </P>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          {/* <div style={{ textAlign: "left", margin: "0px 100px 0px 150px" }}>
            <P
              style={{
                margin: "5px 0px 0px 0px",
                color: "#9a9a9a",
                fontSize: "15px",
              }}
            >
              Product
            </P>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Bold
                style={{
                  fontSize: "15px",
                }}
                onClick={() => {
                  navigate("/features");
                  setActivePage("features");
                  window.scrollTo(0, 0);
                  loadPage();
                }}
              >
                Features
              </Bold>
               <Bold
                style={{
                  fontSize: "15px",
                }}
                onClick={() => {
                  navigate("/pricing");
                  setActivePage("pricing");
                  window.scrollTo(0, 0);
                  loadPage();
                }}
              >
                Pricing
              </Bold> 
              <Bold
                style={{
                  fontSize: "15px",
                }}
                onClick={() => {
                  navigate("/booking");
                  setActivePage("booking");
                  window.scrollTo(0, 0);
                  loadPage();
                }}
              >
                Book Demo
              </Bold>
              <Bold
                style={{
                  fontSize: "15px",
                }}
                onClick={() => {
                  navigate("/feedback");
                  setActivePage("feedback");
                  window.scrollTo(0, 0);
                  loadPage();
                }}
              >
                Product Feedback
              </Bold>
            </div>
          </div> */}
          {/* <div style={{ textAlign: "left" }}>
            <P
              style={{
                margin: "5px 0px 0px 0px",
                color: "#9a9a9a",
                fontSize: "15px",
              }}
            >
              Company
            </P>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Bold
                style={{
                  fontSize: "15px",
                }}
                onClick={() => {
                  navigate("/updates");
                  setActivePage("updates");
                  window.scrollTo(0, 0);
                  loadPage();
                }}
              >
                Updates
              </Bold>
              <Bold
                style={{
                  fontSize: "15px",
                }}
                onClick={() => {
                  navigate("/about");
                  setActivePage("about");
                  window.scrollTo(0, 0);
                  loadPage();
                }}
              >
                About Us
              </Bold>
              <Bold
                style={{
                  fontSize: "15px",
                }}
                onClick={() => {
                  navigate("/contact");
                  setActivePage("contact");
                  window.scrollTo(0, 0);
                  loadPage();
                }}
              >
                Contact Us
              </Bold>
            </div>
          </div> */}
        </div>
      </div>
      <br />
      <br />
      <div
        style={{
          display: isDesktop ? "flex" : "block",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ display: isDesktop ? "flex" : "block", textAlign: "left" }}
        >
          <P style={{ padding: "0px 20px 0px 0px" }}>
            © Copyrights Centrally 2023.
          </P>
          <P
            style={{ margin: isDesktop && "auto 10px" }}
            type="bare"
            onClick={() => {
              navigate("/policies");
              setActivePage("policies");
              loadPage();
            }}
          >
            Privacy Policy & Terms of Use
          </P>
        </div>
        <div style={{ display: "flex", margin: isDesktop ? "0px" : "-10px" }}>
          <img src={linkedin} style={{ width: "20px", padding: "7.5px" }} />
          <img src={facebook} style={{ width: "20px", padding: "7.5px" }} />
          <img src={twitter} style={{ width: "20px", padding: "7.5px" }} />
        </div>
      </div>
      {pageLoaderVisible && (
        <div
          style={{
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            backgroundColor: "#ffffff",
          }}
        ></div>
      )}
    </div>
  );
};

export default Footer;
