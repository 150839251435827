"use es6";

import React, { useState } from "react";
import axios from "axios";
import cryptoRandomString from "crypto-random-string";
import Bold from "../Text/Bold";
import H1 from "../Text/H1";

import exit from "../../graphics/icons/exit.svg";
import checked from "../../graphics/icons/filter/checked.svg";
import unchecked from "../../graphics/icons/filter/unchecked.svg";
import envelope from "../../graphics/icons/envelope.svg";
import padlock from "../../graphics/icons/padlock.svg";
import person from "../../graphics/icons/person.svg";
import eye from "../../graphics/icons/eye.svg";
import P from "../Text/P";
import HR from "../Spacing/HR";
import Card from "../Cards/Card";
import Button from "../Buttons/Button";
import H2 from "../Text/H2";
import Label from "../Text/Label";
import Loading from "../Loading/Loading";

const AddMemberViewport = ({
  credentials = {},
  setMembers = {},
  getCredentials = {},
  addMemberViewportVisible = {},
  setAddMemberViewportVisible = {},
}) => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [verified, setVerified] = useState(false);
  const [addingMember, setAddingMember] = useState(false);

  const activeViewport = addMemberViewportVisible === true;

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const constraintsMet = !(
    name.trim() === "" ||
    email.trim() === "" ||
    role.trim() === "" ||
    verified === null
  );

  const handleAddMember = () => {
    setMessage(null);
    if (!constraintsMet) {
      setMessage("Please ensure that all fields are completed.");
      setAddingMember(false);
      return;
    }

    if (activeViewport) {
      const id = Date.now();

      const newPassword = cryptoRandomString({
        length: 6,
        type: "numeric",
      });

      const requestBody = {
        id: id,
        name: name,
        email: email.toLowerCase(),
        password: newPassword,
        organization: credentials.id,
        role: role,
        verified: verified,
      };
      setAddingMember(true);
      setMessage(`Adding ${email}...`);
      activeViewport &&
        axios
          .post(
            `${process.env.REACT_APP_USERS_API_URL}/signup`,
            requestBody,
            requestConfig
          )
          .then((response) => {
            axios.post(
              `${process.env.REACT_APP_EMAIL_API_URL}/authenticate`,
              {
                message: "create",
                to: email.toLowerCase(),
                organization: credentials.name,
                otp: newPassword,
                name: name,
              },
              requestConfig
            );

            const requestBody2 = {
              members: {
                [`${id}`]: {
                  id: id,
                  name: name,
                  email: email,
                  role: role,
                  verified: verified,
                  image:
                    "https://centrally-defaults.s3.amazonaws.com/defaultprofileimage.png",
                },
              },
            };
            axios
              .post(
                `${process.env.REACT_APP_ORGANIZATIONS_API_URL}/organizations/${credentials.id}`,
                requestBody2,
                requestConfig
              )
              .then(() => {
                getCredentials();
                setMembers((prevMembers) => ({
                  ...prevMembers,
                  [`${id}`]: {
                    id: id,
                    name: name,
                    email: email,
                    role: role,
                    verified: verified,
                    image:
                      "https://centrally-defaults.s3.amazonaws.com/defaultprofileimage.png",
                  },
                }));
                setAddMemberViewportVisible(false);
              });
          })
          .catch((error) => {
            setEmail("");
            setMessage(error.response.data.message);
            setAddingMember(false);
          });
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        zIndex: "20",
      }}
    >
      <Card
        type="white"
        style={{
          width: "500px",
          height: "fit-content",
          margin: "auto",
          padding: "20px 0px 0px 0px",
        }}
      >
        <div>
          <img
            style={{
              width: "24px",
              height: "24px",
              margin: "0px 500px 0px 0px",
            }}
            src={exit}
            alt="An x-mark icon."
            onClick={() => {
              setAddMemberViewportVisible(false);
            }}
          />
          <H2>Add Member</H2>
          <Label>New Member Name</Label>
          <Card type={!!name ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                maxHeight: "fit-content",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    padding: "12px 7.5px 10px 0px",
                    filter: !!name ? "brightness(0%)" : "",
                  }}
                  src={person}
                  alt={`An icon of a human silhouette.`}
                  width="24"
                  height="24"
                />
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!name ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={name}
                  placeholder="Enter this new member's name."
                  onChange={(event) => {
                    setName(event.target.value);
                    setMessage(null);
                  }}
                />
              </div>
            </div>
          </Card>
          <Label>New Member Email</Label>
          <Card type={!!email ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                maxHeight: "fit-content",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    padding: "12px 7.5px 10px 0px",
                    filter: !!email ? "brightness(0%)" : "",
                  }}
                  src={envelope}
                  alt={`An icon of an envelope.`}
                  width="24"
                  height="24"
                />
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!email ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={email}
                  placeholder="Enter this new member email."
                  onChange={(event) => {
                    setEmail(event.target.value.toLowerCase());
                    setMessage(null);
                  }}
                />
              </div>
            </div>
          </Card>

          <Label>New Member Organizational Role</Label>
          <div style={{ display: "flex" }}>
            {["student", "faculty", "staff"].map((tempRole, index) => (
              <div
                key={index}
                onClick={() => {
                  setRole(tempRole);
                  setMessage(null);
                }}
                style={{ padding: "0px 20px 0px 0px" }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Bold>{tempRole.toUpperCase()}</Bold>
                    <div>
                      <input
                        style={{ height: "20px", width: "20px" }}
                        type="radio"
                        value={tempRole}
                        checked={!!role && role === tempRole}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <P
              style={{
                alignItems: "center",
              }}
            >
              New Member Verification
            </P>
            <img
              style={{ padding: "12px 0px" }}
              src={verified ? checked : unchecked}
              alt="An icon of a selectable box."
              width="24"
              height="24"
              onClick={() => {
                setVerified(!verified);
                setMessage(null);
              }}
            />
          </div>
          {addingMember && <Loading width="64px" />}
          <P
            style={{
              fontSize: "11px",
              color: constraintsMet ? "green" : "red",
            }}
          >
            {message}
          </P>
          <Button
            type="dark"
            style={{ margin: "10px 0px", width: "100%" }}
            onClick={() => {
              handleAddMember();
            }}
          >
            Add Member
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default AddMemberViewport;
