"use es6";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../components/Forms/PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import H1 from "../components/Text/H1";
import H0 from "../components/Text/H0";
import P from "../components/Text/P";
import HR from "../components/Spacing/HR";
import Card from "../components/Cards/Card";
import Bold from "../components/Text/Bold";

import glow from "../graphics/images/glow.png";
import feature from "../graphics/icons/feature.svg";
import Button from "../components/Buttons/Button";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_API_KEY);

const PricingPage = ({ isDesktop = {}, dimensions = {} }) => {
  const [timeframe, setTimeframe] = useState("monthly");
  return (
    <div
      style={{
        padding: "160px 30px",
        height: isDesktop ? `${dimensions.height}px` : "1500px",
        backgroundColor: "#ffffff",
      }}
    >
      <H0>💰 Pricing</H0>
      <P style={{ fontSize: "14px", color: "#9a9a9a", margin: "25px" }}>
        See which plan is best for your group. Not sure? Book a demo!
      </P>
      <br />
      <br />
      {/* <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements> */}
      <div
        style={{
          margin: "auto",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "auto",
            maxWidth: "250px",
            backgroundColor: "#dedede",
            borderRadius: "12px",
          }}
        >
          <div
            style={{
              padding: "10px 0px",
              width: "125px",
              height: "18px",
              backgroundColor: timeframe === "monthly" && "#ffffff",
              borderRadius: "12px",
              border: "5px solid #dedede",
            }}
            onClick={() => {
              setTimeframe("monthly");
            }}
          >
            {timeframe === "monthly" ? (
              <Bold>Monthly</Bold>
            ) : (
              <P style={{ margin: "0px" }}>Monthly</P>
            )}
          </div>
          <div
            style={{
              padding: "10px 0px",
              width: "125px",
              height: "18px",
              backgroundColor: timeframe === "yearly" && "#ffffff",
              borderRadius: "12px",
              border: "5px solid #dedede",
            }}
            onClick={() => {
              setTimeframe("yearly");
            }}
          >
            {timeframe === "yearly" ? (
              <Bold>Yearly</Bold>
            ) : (
              <P style={{ margin: "0px" }}>Yearly</P>
            )}
          </div>
        </div>
        <br />
        <div
          style={{
            display: isDesktop && "flex",
            margin: "auto",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <img
              src={glow}
              style={{
                width: isDesktop ? "100%" : "90%",
                height: isDesktop ? "inherit" : "450px",
              }}
            />
            <div
              style={{
                height: "85%",
                width: "72.5%",
                textAlign: "left",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                position: "absolute",
                top: "7.5%",
                left: "15%",
              }}
            >
              <div
                style={{
                  margin: "10px",
                }}
              >
                <br />
                <Bold style={{ margin: "0px" }}>Free</Bold>
                <br />
                <br />
                <H0 style={{ fontSize: "30px" }}>$0</H0>
                <P style={{ fontSize: "14px", color: "#9a9a9a" }}>
                  Per user per {timeframe.split("ly")[0]}
                </P>
                <div style={{ margin: "30px 0px" }}>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>1 Group</P>
                  </div>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>
                      {timeframe === "monthly" ? 20 : 240} Posts Per{" "}
                      {timeframe.split("ly")[0].substring(0, 1).toUpperCase() +
                        timeframe.split("ly")[0].substring(1)}
                    </P>
                  </div>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>100 Total Users</P>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "15px",
                    left: "0px",
                    right: "0px",
                    width: "90%",
                    margin: "auto",
                  }}
                >
                  <Button type="dark" style={{}}>
                    Try Now
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "relative" }}>
            <img
              src={glow}
              style={{
                width: isDesktop ? "100%" : "90%",
                height: isDesktop ? "inherit" : "450px",
              }}
            />
            <div
              style={{
                height: "85%",
                width: "72.5%",
                textAlign: "left",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                position: "absolute",
                top: "7.5%",
                left: "15%",
              }}
            >
              <div
                style={{
                  margin: "10px",
                }}
              >
                <br />
                <Bold style={{ margin: "0px" }}>Starter</Bold>
                <br />
                <br />
                <H0 style={{ fontSize: "30px" }}>
                  ${timeframe === "monthly" ? 2 : 24}
                </H0>
                <P style={{ fontSize: "14px", color: "#9a9a9a" }}>
                  Per user per {timeframe.split("ly")[0]}
                </P>
                <div style={{ margin: "30px 0px" }}>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>10 Groups</P>
                  </div>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>Unlimited Posts</P>
                  </div>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>500 Total Users</P>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "15px",
                    left: "0px",
                    right: "0px",
                    width: "90%",
                    margin: "auto",
                  }}
                >
                  <Button type="dark" style={{}}>
                    Try Now
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "relative" }}>
            <img
              src={glow}
              style={{
                width: isDesktop ? "100%" : "90%",
                height: isDesktop ? "inherit" : "450px",
              }}
            />
            <div
              style={{
                height: "85%",
                width: "72.5%",
                textAlign: "left",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                position: "absolute",
                top: "7.5%",
                left: "15%",
              }}
            >
              <div
                style={{
                  margin: "10px",
                }}
              >
                <br />
                <Bold style={{ margin: "0px" }}>Community</Bold>
                <br />
                <br />
                <H0 style={{ fontSize: "30px" }}>Custom</H0>
                <P style={{ fontSize: "14px", color: "#9a9a9a" }}>
                  Contact sales
                </P>
                <div style={{ margin: "30px 0px" }}>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>Unlimited Groups*</P>
                  </div>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>Unlimited Posts</P>
                  </div>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>Unlimited Users</P>
                  </div>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <img src={feature} style={{ width: "24px" }} />
                    <P style={{ margin: "5px 20px" }}>Additional Features**</P>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "15px",
                    left: "0px",
                    right: "0px",
                    width: "90%",
                    margin: "auto",
                  }}
                >
                  <Button type="dark" style={{}}>
                    Try Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
