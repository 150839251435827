"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { setUserSession } from "../../services/AuthService";

import Loading from "../Loading/Loading";
import P from "../Text/P";
import H0 from "../Text/H0";
import AuthenticationForm from "../Forms/AuthenticationForm";
import Button from "../Buttons/Button";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  onSnapshot,
  addDoc,
  setDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCgCZhMa1z8qIe0zKhqfmDefU7LiUAvl0g",
  authDomain: "centrally-messaging.firebaseapp.com",
  projectId: "centrally-messaging",
  storageBucket: "centrally-messaging.appspot.com",
  messagingSenderId: "383969919309",
  appId: "1:383969919309:web:e7187459ab4becc4b4b704",
  measurementId: "G-JSC1DV08EJ",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const SignupViewport = ({ setCredentials, action, setSignupVisible }) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [constraintsMet, setConstraintsMet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [subscription, setSubscription] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSignup = () => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$/;
    if (
      email.trim() === "" ||
      name.trim() === "" ||
      password.trim() === "" ||
      confirmation.trim() === "" ||
      subscription.trim() === ""
    ) {
      setMessage("Please ensure that all fields are completed.");
      return;
    }
    if (!!passwordRegex.test(password)) {
      setMessage(
        "Please ensure that your password contains at least eight characters, both upper and lower case letters, one number and one special character."
      );
      return;
    }
    if (password.trim() !== confirmation.trim()) {
      setMessage("Please ensure that both passwords match.");
      return;
    }
    setMessage(null);
    const requestConfig = {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    const requestBody = {
      name: name,
      email: email.toLowerCase(),
      password: password,
      subscription: subscription,
    };
    setConstraintsMet(true);
    setMessage("Welcome! 🎉");
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_ORGANIZATIONS_API_URL}/signup`,
        requestBody,
        requestConfig
      )
      .then((response) => {
        setCredentials(response.data.organization);
        navigate("/account");
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      });
  };

  const handleSignin = () => {
    if (email.trim() === "" || password.trim() === "") {
      setMessage("Please ensure that all fields are completed.");
      return;
    }
    setMessage(null);
    const requestConfig = {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    const requestBody = {
      email: email.toLowerCase(),
      password: password,
    };
    setConstraintsMet(true);
    setMessage("Hello again! 👋");
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_ORGANIZATIONS_API_URL}/signin`,
        requestBody,
        requestConfig
      )
      .then((response) => {
        setCredentials(response.data.organization);
        navigate("/account");
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      });
  };

  return (
    <div>
      <div
        style={{
          margin: "30px",
        }}
      >
        <H0>Admin Account</H0>

        {action === "signup" ? (
          <div>
            <P>Sign Up</P>
            <AuthenticationForm
              email={email}
              setEmail={setEmail}
              name={name}
              setName={setName}
              password={password}
              setPassword={setPassword}
              confirmation={confirmation}
              setConfirmation={setConfirmation}
              subscription={subscription}
              setSubscription={setSubscription}
            />
            <div style={{ width: "95%", margin: "0px auto" }}>
              <P
                style={{
                  fontSize: "11px",
                  color: constraintsMet ? "green" : "red",
                }}
              >
                {message}
              </P>
              <div style={{ padding: "0px 0px" }}>
                {isLoading && <Loading />}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <P>Sign In</P>
            <AuthenticationForm
              signup={false}
              email={email}
              setEmail={setEmail}
              name={name}
              setName={setName}
              password={password}
              setPassword={setPassword}
            />
            <P
              style={{
                fontSize: "11px",
                color: constraintsMet ? "green" : "red",
              }}
            >
              {message}
            </P>
            <div style={{ padding: "0px 0px" }}>{isLoading && <Loading />}</div>
          </div>
        )}
        <Button
          type="dark"
          active={
            action === "signup"
              ? !!email &&
                !!name &&
                !!password &&
                !!confirmation &&
                !!subscription
              : action === "signin"
              ? !!email && !!password
              : false
          }
          onClick={() => {
            if (action === "signup") {
              handleSignup();
            } else if (action === "signin") {
              handleSignin();
            }
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default SignupViewport;
