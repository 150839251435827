"use es6";

import React, { useState } from "react";
import Bold from "../Text/Bold";
import H1 from "../Text/H1";

import back from "../../graphics/icons/back.svg";
import P from "../Text/P";
import HR from "../Spacing/HR";
import ProfileButton from "../Buttons/ProfileButton";
import Card from "../Cards/Card";
import Button from "../Buttons/Button";

const UploadImageViewport = ({
  profile = {},
  image = {},
  setImage = {},
  selectedFile = {},
  setSelectedFile = {},
  uploadImageViewportVisible = {},
  setUploadImageViewportVisible = {},
}) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        zIndex: "20",
      }}
    >
      <Card type="white" style={{ height: "fit-content", margin: "auto 20px" }}>
        <label
          id="browse-files-label"
          for="browse-files"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              margin: "auto",
              fontSize: "14px",
              height: "fit-content",
            }}
          >
            Browse Files
          </div>
        </label>
        <input
          id="browse-files"
          type="file"
          onChange={(e) => setSelectedFile(e.target.files[0])}
        />

        {!!selectedFile ? (
          <div>
            <img
              id="blah"
              src={URL.createObjectURL(selectedFile) || "#"}
              style={{
                maxHeight: "300px",
                maxWidth: "100%",
                borderRadius: "12px",
              }}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Button
                style={{ margin: "10px 0px", width: "100%" }}
                onClick={() => {
                  setImage(URL.createObjectURL(selectedFile));
                  setUploadImageViewportVisible(false);
                }}
              >
                Select
              </Button>
            </div>
          </div>
        ) : (
          <p>Your image preview will appear here.</p>
        )}
        <Button
          style={{ color: "#d2566b", margin: "10px 0px", width: "300px" }}
          onClick={() => {
            setUploadImageViewportVisible(false);
          }}
        >
          Cancel
        </Button>
      </Card>
    </div>
  );
};

export default UploadImageViewport;
