"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { getUser, resetUserSession } from "../../services/AuthService";

import banner from "../../graphics/logos/banner.svg";
import filter from "../../graphics/icons/header/filter.svg";
import arrow from "../../graphics/icons/arrow.svg";
import gear from "../../graphics/icons/header/gear.svg";
import back from "../../graphics/icons/back.svg";

import Bold from "../Text/Bold";
import Button from "../Buttons/Button";
import P from "../Text/P";

const Header = ({
  isDesktop = {},
  credentials = {},
  setCredentials = {},
  feedSelection = {},
  setFeedSelection = {},
  filterSelection = {},
  setFilterSelection = {},
  settingsViewportVisible = {},
  setSettingsViewportVisible = {},
  createGroupViewportVisible = {},
  setCreateGroupViewportVisible = {},
  shareDropdownVisible = {},
  setShareDropdownVisible = {},
  activePage = {},
  setActivePage = {},
  setBookDemoViewportVisible = {},
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [pageLoaderVisible, setPageLoaderVisible] = useState(false);

  const loadPage = async () => {
    setPageLoaderVisible(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    setPageLoaderVisible(false);
  };

  return (
    <div
      className="header"
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        alignItems: "center",
        justifyContent: isDesktop ? "space-between" : "center",
      }}
    >
      <div
        style={{
          display: isDesktop && "flex",
          alignItems: "center",
          marginTop: !isDesktop && "-20px",
        }}
      >
        <div style={{ margin: isDesktop ? "0px 15px" : "0px 0px 10px -30px" }}>
          <img
            src={banner}
            onClick={() => {
              navigate("/");
              setActivePage("home");
              window.scrollTo(0, 0);
              loadPage();
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: isDesktop ? "-4px 0px 0px 0px" : "-4px 0px 0px -30px",
          }}
        >
          {/* <div
            style={{
              width: "90px",
            }}
          >
            <Bold
              style={{
                opacity: activePage === "updates" ? "100%" : "50%",
              }}
              onClick={() => {
                navigate("/updates");
                setActivePage("updates");
                window.scrollTo(0, 0);
                loadPage();
              }}
            >
              Updates
            </Bold>
          </div>
          <div
            style={{
              width: "90px",
            }}
          >
            <Bold
              style={{
                opacity: activePage === "about" ? "100%" : "50%",
              }}
              onClick={() => {
                navigate("/about");
                setActivePage("about");
                window.scrollTo(0, 0);
                loadPage();
              }}
            >
              About Us
            </Bold>
          </div> */}
          {/* <div
            style={{
              width: "90px",
            }}
          >
            <Bold
              style={{
                opacity: activePage === "pricing" ? "100%" : "50%",
              }}
              onClick={() => {
                navigate("/pricing");
                setActivePage("pricing");
                window.scrollTo(0, 0);
              }}
            >
              Pricing
            </Bold>
          </div> */}
          {/* <div
            style={{
              width: "110px",
            }}
          >
            <Bold
              style={{
                opacity: activePage === "booking" ? "100%" : "50%",
              }}
              onClick={() => {
                setActivePage("booking");
                window.scrollTo(0, 0);
                setBookDemoViewportVisible(true);
              }}
            >
              Book Demo
            </Bold>
          </div> */}
        </div>
      </div>
      {/* {isDesktop && (
        <div
          style={{
            display: "flex",
            padding: "0px 15px 0px 0px",
            width: "300px",
            justifyContent: "right",
          }}
        >
          {!!credentials ? (
            <div
              style={{
                margin: "0px 30px",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: isDesktop ? "inherit" : "100%",
                }}
              >
                <Button
                  type="bare"
                  onClick={() => {
                    localStorage.removeItem("centrally-admin-credentials");
                    navigate("/");
                    window.location.reload();
                    loadPage();
                  }}
                >
                  Sign Out
                </Button>
              </div>
              <div
                style={{
                  width: isDesktop ? "inherit" : "100%",
                }}
              >
                <Button
                  type="dark2"
                  onClick={() => {
                    navigate("/account");
                    loadPage();
                  }}
                >
                  Account
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                margin: "0px 30px",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: isDesktop ? "inherit" : "100%",
                }}
              >
                <Button
                  type="bare"
                  onClick={() => {
                    navigate("/account/?action=signup");
                    loadPage();
                  }}
                >
                  Sign Up
                </Button>
              </div>
              <div
                style={{
                  width: isDesktop ? "inherit" : "100%",
                }}
              >
                <Button
                  type="dark2"
                  onClick={() => {
                    navigate("/account/?action=signin");
                    loadPage();
                  }}
                >
                  Sign In
                </Button>
              </div>
            </div>
          )}
        </div>
      )} */}
      {pageLoaderVisible && (
        <div
          style={{
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            backgroundColor: "#ffffff",
          }}
        ></div>
      )}
    </div>
  );
};

export default Header;
