"use es6";

import React from "react";

const P = ({
  children = "[insert text]",
  className = "",
  style = {},
  ...buttonProps
}) => {
  return (
    <p className={className} style={style} {...buttonProps}>
      {children}
    </p>
  );
};

export default P;
