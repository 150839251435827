"use es6";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import H1 from "../components/Text/H1";
import AuthenticationViewport from "../components/Viewports/AuthenticationViewport";
import Button from "../components/Buttons/Button";
import AddMemberViewport from "../components/Viewports/AddMemberViewport";
import Bold from "../components/Text/Bold";
import HR from "../components/Spacing/HR";
import H2 from "../components/Text/H2";
import Card from "../components/Cards/Card";
import EditMemberViewport from "../components/Viewports/EditMemberViewport";

const AuthenticationPage = ({
  isDesktop = {},
  credentials = {},
  dimensions = {},
  setCredentials = {},
}) => {
  const [searchParams] = useSearchParams();
  const [authenticationVisible, setAuthenticationVisible] = useState(true);

  return (
    <div style={{ margin: "120px 30px" }}>
      {authenticationVisible &&
        (searchParams.get("action") === "signup" ||
          searchParams.get("action") === "signin") && (
          <AuthenticationViewport
            setCredentials={setCredentials}
            action={searchParams.get("action")}
            setAuthenticationVisible={setAuthenticationVisible}
          />
        )}
    </div>
  );
};

export default AuthenticationPage;
