"use es6";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import H1 from "../components/Text/H1";
import H0 from "../components/Text/H0";
import P from "../components/Text/P";
import HR from "../components/Spacing/HR";

const FeaturesPage = ({ dimensions = {} }) => {
  const [timeframe, setTimeframe] = useState("monthly");
  return (
    <div
      style={{
        padding: "160px 30px",
        height: `${dimensions.height}px`,
        backgroundColor: "#ffffff",
      }}
    >
      <H0>⚙️ Features</H0>
      <P style={{ fontSize: "14px", color: "#9a9a9a", margin: "25px" }}>
        Here's a look under the hood.
      </P>
      <br />
      <br />
      <HR />
      <br />
      <H1>Coming Soon...</H1>
    </div>
  );
};

export default FeaturesPage;
