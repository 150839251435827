"use es6";

import React from "react";

import logo from "../../graphics/logos/logo.png";

const Loading = ({ width = "20px" }) => {
  return (
    <div className="spinning">
      <img
        style={{
          width: width,
          opacity: "50%",
          animation: "rotate 0.5s infinite linear",
        }}
        src={logo}
      />
    </div>
  );
};

export default Loading;
