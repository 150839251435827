"use es6";

import React from "react";

const H2 = ({ children = "[insert text]", ...buttonProps }) => {
  return (
    <p className="heading-2" {...buttonProps}>
      {children}
    </p>
  );
};

export default H2;
