"use es6";

import React from "react";

const Bold = ({
  children = "[insert text]",
  style = { padding: "10px" },
  ...buttonProps
}) => {
  return (
    <b className="bold" style={style} {...buttonProps}>
      {children}
    </b>
  );
};

export default Bold;
