"use es6";

import React from "react";

const HR = () => {
  return (
    <hr style={{ height: "1px", backgroundColor: "#edeff3", border: "none" }} />
  );
};

export default HR;
