"use es6";

import React, { useState } from "react";
import axios from "axios";
import Bold from "../Text/Bold";
import H1 from "../Text/H1";

import exit from "../../graphics/icons/exit.svg";
import checked from "../../graphics/icons/filter/checked.svg";
import unchecked from "../../graphics/icons/filter/unchecked.svg";
import envelope from "../../graphics/icons/envelope.svg";
import padlock from "../../graphics/icons/padlock.svg";
import person from "../../graphics/icons/person.svg";
import eye from "../../graphics/icons/eye.svg";
import P from "../Text/P";
import HR from "../Spacing/HR";
import Card from "../Cards/Card";
import Button from "../Buttons/Button";
import H2 from "../Text/H2";
import Label from "../Text/Label";
import Loading from "../Loading/Loading";

const BookDemoViewport = ({
  credentials = {},
  getCredentials = {},
  setBookDemoViewportVisible = {},
}) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [size, setSize] = useState();
  const [bookingDemo, setBookingDemo] = useState(false);

  const bookButtonActive = !!name && !!email && !!phone && !!size;

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const handleBookDemo = () => {
    const requestBody = {
      name: name,
      email: email.toLowerCase(),
      phone: phone,
      size: size,
    };
    setBookingDemo(true);

    axios
      .post(
        `${process.env.REACT_APP_EMAIL_API_URL}/sales`,
        {
          message: "demo",
          to: "tolu@centrally.co",
          name: name,
          email: email.toLowerCase(),
          phone: phone,
          size: size,
        },
        requestConfig
      )
      .then(async (response) => {
        await new Promise((resolve) => setTimeout(resolve, 300));
        setBookDemoViewportVisible(false);
      });
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        zIndex: "20",
      }}
    >
      <Card
        type="white"
        style={{
          width: "500px",
          height: "fit-content",
          margin: "auto",
          padding: "20px 0px 0px 0px",
        }}
      >
        <div>
          <img
            style={{
              width: "24px",
              height: "24px",
              margin: "0px 500px 0px 0px",
            }}
            src={exit}
            alt="An x-mark icon."
            onClick={() => {
              setBookDemoViewportVisible(false);
            }}
          />
          <H2>Let's find time to chat</H2>
          <Card type={!!name ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                height: "45px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!name ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={name}
                  placeholder="Your name"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
            </div>
          </Card>
          <Card type={!!email ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                height: "45px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!email ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={email}
                  placeholder="Email address"
                  onChange={(event) => {
                    setEmail(event.target.value.toLowerCase());
                  }}
                />
              </div>
            </div>
          </Card>
          <Card type={!!phone ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                height: "45px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!phone ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={phone}
                  placeholder="Phone number"
                  onChange={(event) => {
                    setPhone(event.target.value.toLowerCase());
                  }}
                />
              </div>
            </div>
          </Card>
          <Card type={!!size ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                height: "45px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!size ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={size}
                  placeholder="Approximate organization size"
                  onChange={(event) => {
                    setSize(event.target.value.toLowerCase());
                  }}
                />
              </div>
            </div>
          </Card>

          {bookingDemo && <Loading width="64px" />}
          <Button
            type="dark"
            active={bookButtonActive}
            style={{ width: "250px", margin: "25px 0px 0px 0px" }}
            onClick={() => {
              handleBookDemo();
            }}
          >
            Sumbit
          </Button>
          <P
            style={{
              fontSize: "12px",
              color: "#9a9a9a",
              width: "300px",
              margin: "25px auto",
            }}
          >
            By submitting my information, I confirm that i have read and
            understood the clickup{" "}
            <span style={{ color: "#000000" }}>Privacy Policy.</span>
          </P>
        </div>
      </Card>
    </div>
  );
};

export default BookDemoViewport;
