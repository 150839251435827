"use es6";

import React from "react";

const Card = ({
  children = "[insert text]",
  type = "inactive",
  height = "fit-content",
  scrollable = false,
  ...buttonProps
}) => {
  const className = `card-${type}`;
  return (
    <div
      className={className}
      style={{
        height: height,
        overflowX: scrollable ? "scroll" : "hidden",
        overflowY: scrollable ? "scroll" : "hidden",
      }}
      {...buttonProps}
    >
      <div style={{ padding: "0px 10px" }}>{children}</div>
    </div>
  );
};

export default Card;
