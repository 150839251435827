"use es6";

import React from "react";

const Label = ({ children = "[insert text]", ...buttonProps }) => {
  return (
    <p
      style={{ textAlign: "left", fontSize: "14px", height: "10px" }}
      {...buttonProps}
    >
      {children}
    </p>
  );
};

export default Label;
