"use es6";

import React, { useState } from "react";

import school from "../../graphics/icons/school.svg";
import envelope from "../../graphics/icons/envelope.svg";
import padlock from "../../graphics/icons/padlock.svg";
import person from "../../graphics/icons/person.svg";
import eye from "../../graphics/icons/eye.svg";
import Card from "../Cards/Card";
import { useWindowDimensions } from "../../utils/CustomHooks";
import Label from "../Text/Label";
import Bold from "../Text/Bold";

const AuthenticationForm = ({
  signup = true,
  email = "",
  setEmail,
  name = "",
  setName,
  password = "",
  setPassword,
  confirmation = "",
  setConfirmation,
  subscription = "",
  setSubscription,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <div>
      <Label>Organization Admin Email</Label>
      <Card type={!!email ? "active" : "inactive"}>
        <div
          style={{
            display: "flex",
            maxHeight: "fit-content",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <img
              style={{
                padding: "12px 7.5px 10px 0px",
                filter: !!email ? "brightness(0%)" : "",
              }}
              src={envelope}
              alt={`An icon of an envelope.`}
              width="24"
              height="24"
            />
            <input
              type="text"
              style={{
                alignItems: "center",
                fontSize: "15px",
                width: "100%",
                border: "none",
                color: !!email ? "#000000" : "#9a9a9a",
                backgroundColor: "rgba(0,0,0,0)",
              }}
              value={email}
              placeholder="Enter your organization email."
              onChange={(event) => {
                setEmail(event.target.value.toLowerCase());
              }}
            />
          </div>
        </div>
      </Card>
      {!!signup && (
        <div>
          <Label>Organization Name</Label>
          <Card type={!!name ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                maxHeight: "fit-content",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    padding: "12px 7.5px 10px 0px",
                    filter: !!name ? "brightness(0%)" : "",
                  }}
                  src={school}
                  alt={`An icon of a school building.`}
                  width="24"
                  height="24"
                />
                <input
                  type="text"
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!name ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  value={name}
                  placeholder="Enter your organization's name."
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
            </div>
          </Card>
        </div>
      )}
      <Label>Password</Label>
      <Card type={!!password ? "active" : "inactive"}>
        <div
          style={{
            display: "flex",
            maxHeight: "fit-content",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <img
              style={{
                padding: "12px 7.5px 10px 0px",
                filter: !!password ? "brightness(0%)" : "",
              }}
              src={padlock}
              alt={`An icon of an envelope.`}
              width="24"
              height="24"
            />
            <input
              type={!!passwordVisible ? "text" : "password"}
              style={{
                alignItems: "center",
                fontSize: "15px",
                width: "100%",
                border: "none",
                color: !!password ? "#000000" : "#9a9a9a",
                backgroundColor: "rgba(0,0,0,0)",
              }}
              placeholder="Enter your password."
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <img
              style={{
                padding: "12px 0px 10px 0px",
                filter: !!passwordVisible ? "brightness(0%)" : "",
              }}
              src={eye}
              alt={`An icon of an eye.`}
              width="28"
              height="28"
              onClick={() => {
                setPasswordVisible(!passwordVisible);
              }}
            />
          </div>
        </div>
      </Card>
      {!!signup && (
        <div>
          <Label>Confirm Password</Label>
          <Card type={!!confirmation ? "active" : "inactive"}>
            <div
              style={{
                display: "flex",
                maxHeight: "fit-content",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    padding: "12px 7.5px 10px 0px",
                    filter: !!confirmation ? "brightness(0%)" : "",
                  }}
                  src={padlock}
                  alt={`An icon of an envelope.`}
                  width="24"
                  height="24"
                />
                <input
                  type={!!passwordVisible ? "text" : "password"}
                  style={{
                    alignItems: "center",
                    fontSize: "15px",
                    width: "100%",
                    border: "none",
                    color: !!confirmation ? "#000000" : "#9a9a9a",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  placeholder="Confirm your password."
                  onChange={(event) => {
                    setConfirmation(event.target.value);
                  }}
                />
              </div>
            </div>
          </Card>
          <div style={{ display: "flex" }}>
            {["free", "standard", "deluxe"].map((tempSubscription, index) => (
              <div
                key={index}
                onClick={() => {
                  setSubscription(tempSubscription);
                }}
                style={{ padding: "0px 20px 0px 0px" }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Bold>{tempSubscription.toUpperCase()}</Bold>
                    <div>
                      <input
                        style={{ height: "20px", width: "20px" }}
                        type="radio"
                        value={tempSubscription}
                        checked={
                          !!subscription && subscription === tempSubscription
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <br />
    </div>
  );
};

export default AuthenticationForm;
