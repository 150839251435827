"use es6";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import H1 from "../components/Text/H1";
import H0 from "../components/Text/H0";

import landing1 from "../graphics/images/landing1.png";
import carousel1 from "../graphics/images/carousel1.png";
import carousel2 from "../graphics/images/carousel2.png";
import P from "../components/Text/P";
import Button from "../components/Buttons/Button";
import Card from "../components/Cards/Card";

const HomePage = ({
  isDesktop = {},
  credentials = {},
  dimensions = {},
  setBookDemoViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const [carouselSlide, setCarouselSlide] = useState(0);
  const [email, setEmail] = useState(null);

  return (
    <div
      style={{
        margin: "110px 0px 250px 0px",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: isDesktop ? "flex" : "block",
          height: "fit-content",
          background: "linear-gradient(180deg, #ffffff 0%, #efefef 100%)",
        }}
      >
        <div
          style={{
            margin: "0px 30px",
            padding: "30px 0px",
            textAlign: "left",
            flex: "1",
          }}
        >
          <H0>Get connected to each other in one place 🤝</H0>
          <P style={{ color: "#9a9a9a" }}>
            Keep everyone informed, engaged, and connected all while staying
            organized and focused on what matters most: your community
          </P>
          <div style={{ display: isDesktop && "flex", width: "100%" }}>
            <div
              style={{
                width: "100%",
                margin: "20px 0px",
                marginRight: isDesktop && "20px",
              }}
            >
              <Button
                type="dark"
                style={{ minWidth: "150px" }}
                onClick={() => setBookDemoViewportVisible(true)}
              >
                Book Demo
              </Button>
            </div>
            {/* <div style={{ width: "100%", margin: "20px 0px" }}>
              <Button
                type="light"
                style={{ backgroundColor: "#ffffff" }}
                onClick={() => {
                  navigate("/account/?action=signup");
                }}
              >
                {!!credentials ? "Account" : "Sign Up"}
              </Button>
            </div> */}
          </div>
        </div>
        <div style={{ margin: "30px", flex: "1.5" }}>
          <img src={landing1} style={{ maxWidth: "100%" }} />
        </div>
      </div>
      <div style={{ margin: "30px" }}>
        <H0>About our product</H0>
        <div style={{ padding: "30px 0px 0px 0px" }}>
          <img
            src={carouselSlide === 0 ? carousel1 : carousel2}
            style={{ maxWidth: "100%" }}
            onClick={() => {
              setCarouselSlide((prevCarouselSlide) =>
                prevCarouselSlide === 1 ? 0 : prevCarouselSlide + 1
              );
            }}
          />
        </div>
      </div>
      <br />
      ...and much more on the way!
      {/* <div style={{ margin: "30px" }}>
        <H0>How Centrally works</H0>
        <div
          style={{
            margin: "30px auto",
            display: "block",
            overflow: "hidden",
            maxWidth: "600px",
            aspectRatio: "16/9",
            borderRadius: "20px",
            transform: "translateZ(0px)",
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/jNQXAC9IVRw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div style={{ margin: "60px 30px 0px 30px" }}>
        <H0>Learn about updates</H0>
        <div style={{ padding: "0px 0px 0px 0px" }}>
          <P style={{ color: "#9a9a9a" }}>
            Stay tuned and keep up with our new features as we scale!
          </P>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "600px",
              margin: "auto",
            }}
          >
            <Card
              type={!!email ? "active" : "inactive"}
              style={{
                width: "70%",
                height: "48px",
                margin: "0px 15px 0px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "40px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      alignItems: "center",
                      fontSize: "15px",
                      width: "100%",
                      border: "none",
                      color: !!email ? "#000000" : "#9a9a9a",
                      backgroundColor: "rgba(0,0,0,0)",
                    }}
                    placeholder="Your email address."
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>
            </Card>
            <Button type="dark" style={{ minWidth: "135px", margin: "0px" }}>
              Join Now
            </Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default HomePage;
