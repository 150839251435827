"use es6";

import React from "react";
import Bold from "../Text/Bold";

const Button = ({
  children = "[insert text]",
  type = "light",
  active = true,
  ...buttonProps
}) => {
  const className = `button-${type}`;
  return (
    <div style={{ opacity: active ? "100%" : "50%" }}>
      <button disabled={!active} className={className} {...buttonProps}>
        <div style={{ padding: "15px 0px" }}>
          <Bold style={{ fontSize: "16px" }}>{children}</Bold>
        </div>
      </button>
    </div>
  );
};

export default Button;
