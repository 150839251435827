"use es6";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import H1 from "../components/Text/H1";
import P from "../components/Text/P";
import Card from "../components/Cards/Card";
import Bold from "../components/Text/Bold";
import H0 from "../components/Text/H0";
import HR from "../components/Spacing/HR";

const UpdatesPage = ({ dimensions = {} }) => {
  const updates = [
    {
      version: "1.0.0",
      title: "Platform launched!",
      date: 1680840000000,
    },
    { version: "1.0.1", title: "Minor UX updates.", date: 1681755645000 },
  ];
  return (
    <div
      style={{
        padding: "160px 30px",
        height: `${dimensions.height}px`,
        backgroundColor: "#ffffff",
      }}
    >
      <H0>🥳 Updates</H0>
      <P style={{ fontSize: "14px", color: "#9a9a9a", margin: "25px" }}>
        What are we up to? Find out below.
      </P>
      <br />
      <br />
      <HR />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Bold
            style={{
              fontSize: "20px",
              padding: "0px",
              margin: "30px 0px 15px 0px",
            }}
          >
            Latest Updates
          </Bold>
          <Bold
            style={{
              fontSize: "20px",
              padding: "0px",
              margin: "30px 0px 15px 0px",
            }}
          >
            Release Date
          </Bold>
        </div>
        {updates.map((update, index) => (
          <div>
            <Card type="white">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <P style={{ padding: "0px 10px 0px 5px" }}>{update.title}</P>
                  <P className="sub-text">(ver. {update.version})</P>
                </div>
                <div style={{ display: "flex" }}>
                  <P>
                    {new Date(update.date).toLocaleString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </P>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default UpdatesPage;
