"use es6";

import "./App.css";
import React, { useState, useEffect } from "react";

import HomePage from "./pages/HomePage";
import UnknownPage from "./pages/UnknownPage";
import { Route, Routes } from "react-router-dom";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PrivateRoute from "./routes/PrivateRoute";
import {
  getUser,
  getToken,
  setUserSession,
  resetUserSession,
} from "./services/AuthService";
import axios from "axios";
import { useWindowDimensions } from "./utils/CustomHooks";
import AboutPage from "./pages/AboutPage";
import BookingPage from "./pages/BookingPage";
import PoliciesPage from "./pages/PoliciesPage";
import PricingPage from "./pages/PricingPage";
import TermsPage from "./pages/TermsPage";
import UpdatesPage from "./pages/UpdatesPage";
import AccountPage from "./pages/AccountPage";
import AuthenticationPage from "./pages/AuthenticationPage";
import ContactPage from "./pages/ContactPage";
import FeaturesPage from "./pages/FeaturesPage";
import FeedbackPage from "./pages/FeedbackPage";
import BookDemoViewport from "./components/Viewports/BookDemoViewport";

const App = () => {
  const [isAuthenicating, setAuthenicating] = useState(true);
  const location = useLocation();

  const dimensions = useWindowDimensions();
  const isDesktop = dimensions.width > 930;

  const [credentials, setCredentials] = useState(null);
  const [feedSelection, setFeedSelection] = useState({
    announcements: true,
    events: true,
  });
  const [filterSelection, setFilterSelection] = useState({
    social: true,
    academic: true,
    professional: true,
    opportunity: true,
    other: true,
    urgent: false,
  });
  const [notificationsViewportVisible, setNotificationsViewportVisible] =
    useState(false);
  const [bookDemoViewportVisible, setBookDemoViewportVisible] = useState(false);
  const [attendeesViewportVisible, setAttendeesViewportVisible] =
    useState(false);
  const [shareDropdownVisible, setShareDropdownVisible] = useState(false);
  const [postData, setPostData] = useState(false);
  const [activePage, setActivePage] = useState(
    location.pathname.length < 4
      ? "home"
      : location.pathname.indexOf("profile/") !== -1
      ? "profile"
      : location.pathname.indexOf("groups") !== -1
      ? "groups"
      : "chats"
  );

  const [headerMode, setHeaderMode] = useState("feed");

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  useEffect(() => {
    const data = window.localStorage.getItem("centrally-admin-credentials");
    if (data !== null) {
      setCredentials(JSON.parse(data));
    }
    // console.log(data);
  }, []);

  const updateCredentials = (credentials) => {
    if (credentials !== null)
      window.localStorage.setItem(
        "centrally-admin-credentials",
        JSON.stringify(credentials)
      );
  };

  useEffect(() => {
    updateCredentials(credentials);
  }, [credentials]);

  useEffect(() => {
    const token = getToken();
    if (
      token === "undefined" ||
      token === undefined ||
      token === null ||
      !token
    ) {
      return;
    }

    const requestConfig = {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    const requestBody = {
      user: getUser(),
      token: token,
    };

    axios
      .post(
        `${process.env.REACT_APP_USERS_API_URL}/verify`,
        requestBody,
        requestConfig
      )
      .then((response) => {
        setUserSession(response.data.user, response.data.token);
        setAuthenicating(false);
      })
      .catch(() => {
        resetUserSession();
        setAuthenicating(false);
      });
  }, []);

  const token = getToken();
  // while (isAuthenicating && token) {
  //   return <div className="App">Authenicating...</div>;
  // }

  return (
    <div className="App">
      {
        <Header
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          feedSelection={feedSelection}
          setFeedSelection={setFeedSelection}
          filterSelection={filterSelection}
          setFilterSelection={setFilterSelection}
          headerMode={headerMode}
          setHeaderMode={setHeaderMode}
          attendeesViewportVisible={attendeesViewportVisible}
          setAttendeesViewportVisible={setAttendeesViewportVisible}
          shareDropdownVisible={shareDropdownVisible}
          setShareDropdownVisible={setShareDropdownVisible}
          activePage={activePage}
          setActivePage={setActivePage}
          setBookDemoViewportVisible={setBookDemoViewportVisible}
        />
      }
      {!!bookDemoViewportVisible && (
        <BookDemoViewport
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          bookDemoViewportVisible={bookDemoViewportVisible}
          setBookDemoViewportVisible={setBookDemoViewportVisible}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              isDesktop={isDesktop}
              credentials={credentials}
              dimensions={dimensions}
              setBookDemoViewportVisible={setBookDemoViewportVisible}
            />
          }
        />
        <Route
          path="/about"
          element={<AboutPage isDesktop={isDesktop} dimensions={dimensions} />}
        />
        <Route
          path="/account"
          element={
            !!credentials ? (
              <AccountPage
                isDesktop={isDesktop}
                credentials={credentials}
                dimensions={dimensions}
                setCredentials={setCredentials}
                updateCredentials={updateCredentials}
                authenticated={true}
              />
            ) : (
              <AuthenticationPage
                isDesktop={isDesktop}
                credentials={credentials}
                dimensions={dimensions}
                setCredentials={setCredentials}
              />
            )
          }
        />
        <Route
          path="/booking"
          element={
            <BookingPage isDesktop={isDesktop} dimensions={dimensions} />
          }
        />
        <Route
          path="/contact"
          element={
            <ContactPage isDesktop={isDesktop} dimensions={dimensions} />
          }
        />
        <Route
          path="/features"
          element={
            <FeaturesPage isDesktop={isDesktop} dimensions={dimensions} />
          }
        />
        <Route
          path="/feedback"
          element={
            <FeedbackPage isDesktop={isDesktop} dimensions={dimensions} />
          }
        />
        <Route
          path="/policies"
          element={
            <PoliciesPage isDesktop={isDesktop} dimensions={dimensions} />
          }
        />
        <Route
          path="/pricing"
          element={
            <PricingPage isDesktop={isDesktop} dimensions={dimensions} />
          }
        />
        <Route
          path="/terms"
          element={<TermsPage isDesktop={isDesktop} dimensions={dimensions} />}
        />
        <Route
          path="/updates"
          element={
            <UpdatesPage isDesktop={isDesktop} dimensions={dimensions} />
          }
        />

        <Route
          path="*"
          element={
            <UnknownPage isDesktop={isDesktop} dimensions={dimensions} />
          }
        />
      </Routes>
      {
        <Footer
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          notificationsViewportVisible={notificationsViewportVisible}
          setNotificationsViewportVisible={setNotificationsViewportVisible}
          headerMode={headerMode}
          setHeaderMode={setHeaderMode}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      }
    </div>
  );
};

export default App;
