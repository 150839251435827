"use es6";

import React from "react";

const H0 = ({ children = "[insert text]", ...buttonProps }) => {
  return (
    <b className="heading-0" {...buttonProps}>
      {children}
    </b>
  );
};

export default H0;
